.ReactTags__tags {
    position: relative;
  }
  
  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 48px;
    margin: 0 8px;
    font-size: 12px;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 0 10px;
    width: 100%;
    min-width: 150px;
    outline: none;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #718096;
    color: white;
    font-size: 14px;
    display: inline-block;
    padding: 8px;
    margin: 0 4px;
    border-radius: 18px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }
  
  .ReactTags__error {
    color: #e03131;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  
    svg {
      padding: 0 5px;
    }
  }
  