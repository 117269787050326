.dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.line {
  width: 1px;
  height: 100%;
  background-color: #CBD5E0; /* gray.300 color */
  position: absolute;
  left: 4px; /* Adjust this value to align the line with the dot */
  top: 0;
  z-index: 0;
}

.step {
  padding-left: 20px;
}